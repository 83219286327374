<template>
  <div :class="{ 'c-dark-theme':  $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 배치작업 설정 --------------------------------------------------------------------------------------------->
    <CCard border-color="info" style="min-height: 600px">
      <CCardHeader>
        <BRow>
          <BCol>
            <BIconAppIndicator class="mr-2"/> <strong> 배치 작업정보 </strong>
          </BCol>
          <BCol class="text-right">
            <BButtonGroup>
              <BButton size="sm" variant="info" class="mr-1" @click="getJob">
                <BIconArrowClockwise/>
              </BButton>
              <BButton size="sm" variant="warning" class="mr-3" @click="addJob">
                <BIconNodePlusFill/> 작업 추가
              </BButton>
              <BButton size="sm" variant="primary" type="submit" @click="applyJob" :disabled="isApplyJob">
                <BIconBootstrapReboot/> 설정 반영
              </BButton>
            </BButtonGroup>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody>

        <BTable
          small
          bordered
          responsive
          selectable
          select-mode="single"
          selected-variant="danger"
          sticky-header
          sticky-column
          ref="jobTable"
          thead-class="text-center"
          head-variant="light"
          class="text-nowrap small text-center"
          :fields="jobFields"
          :items="jobRows"
          @row-selected="jobSelected"
        >
          <template #cell(status)="{item}">
            <BBadge class="small" :variant="statusVariant(item.status)">{{statusName(item.status)}}</BBadge>
          </template>

        </BTable>

        <BForm @submit="jobSubmit" autocomplete="off">
          <BCard class="p-2 small mb-0" v-if="job!==null" border-variant="info" no-body>

            <!-- jobName -->
            <BFormGroup
              size="sm" class="mb-1"
              label="작업 명"
              label-for="jobName"
              label-cols-md="3"
              valid-feedback="사용 가능한 작업명입니다"
              invalid-feedback="예) 재고데이터 전송작업"
              :state="job.name.length > 2"
            >
              <BFormInput size="sm" id="jobName"
                          v-model="job.name"
                          :state="job.name.length > 2"
                          maxLength="50"
                          required
                          trim/>
            </BFormGroup>
            <!-- description -->
            <BFormGroup
              size="sm" class="mb-1"
              label="작업 설명"
              label-for="jobDesc"
              label-cols-md="3"
            >
              <BFormInput size="sm" id="jobDesc"
                          v-model="job.description"
                          maxLength="200"
                          trim/>
            </BFormGroup>
            <!-- jobId -->
            <BFormGroup
              size="sm" class="mb-1"
              label="작업 코드"
              label-for="jobId"
              label-cols-md="3"
              valid-feedback="사용 가능한 코드입니다"
              invalid-feedback="숫자 4자 입력 예) 0001"
              :state="isValidExp(job.jobId, 'num', 4)"
            >
              <BFormInput size="sm" id="jobId"
                          v-model="job.jobId"
                          @input="v=>(job.jobId = v.toUpperCase())"
                          :state="isValidExp(job.jobId, 'num', 4)"
                          maxLength="4"
                          required
                          trim/>
            </BFormGroup>
            <!-- jobEnabled -->
            <BFormGroup
              size="sm" class="mb-1"
              label="실행여부"
              label-for="jobEnabled"
              label-cols-md="3"
            >
              <BFormCheckbox id="jobEnabled"
                             class="mt-1"
                             v-model="job.enabled" switch>
                {{`${job.enabled?'작업 실행':'실행 중지'}`}}
              </BFormCheckbox>
            </BFormGroup>
            <div v-show="job.enabled">
              <!-- scheduled -->
              <BFormGroup
                size="sm" class="mb-0"
                label="실행 일정 설정"
                label-for="scheduled"
                label-cols-md="3"
              >
                <BFormCheckbox id="scheduled"
                               class="mt-1"
                               v-model="job.scheduled" switch>
                  {{`${job.scheduled?'정해진 일정 실행':'특정 이벤트 발생시 실행'}`}}
                </BFormCheckbox>
              </BFormGroup>
              <div v-show="job.scheduled" class="small">
                <VueCronEditorBuefy v-model="job.runSchedule"
                                    class="small font-weight-bold text-nowrap"
                                    locale="ko"
                                    :custom-locales="locale"

                />
              </div>
              <!-- eventName -->
              <BFormGroup v-show="!job.scheduled"
                          size="sm" class="mb-1"
                          label="이벤트 명"
                          label-for="eventName"
                          label-cols-md="3"
              >
                <BFormInput size="sm" id="eventName"
                            v-model="job.eventName"
                            maxLength="40"
                            trim/>
              </BFormGroup>
              <!-- dbType -->
              <CSelect size="sm" class="mb-1"
                       horizontal
                       label="작업 형태"
                       :value.sync="job.dbType"
                       :options="dbTypeOpts"
              />
              <!-- serverIp -->
              <BFormGroup
                size="sm" class="mb-1"
                label="서버 IP 주소"
                label-for="serverIp2"
                label-cols-md="3"
                valid-feedback="IP 주소 형식입니다"
                invalid-feedback="IP 주소  예) 192.168.10.123"
                :state="isValidExp(job.serverIp, 'ip')"
              >
                <BFormInput size="sm" id="serverIp2"
                            v-model="job.serverIp"
                            :state="isValidExp(job.serverIp, 'ip')"
                            maxLength="40"
                            trim/>
              </BFormGroup>
              <!-- baseUrl -->
              <BFormGroup
                size="sm" class="mb-1"
                label="Base URL"
                label-for="baseURL"
                label-cols-md="3"
                valid-feedback="URL 형식 입니다"
                invalid-feedback="URL 예) http://192.168.10.123:8080"
                :state="isValidExp(job.baseUrl, 'url')"
              >
                <BFormInput size="sm" id="baseURL"
                            v-model="job.baseUrl"
                            :state="isValidExp(job.baseUrl, 'url')"
                            maxLength="400"
                            trim/>
              </BFormGroup>

              <BFormGroup
                size="sm" class="mb-1"
                label="API 경로"
                label-for="apiURL"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="apiPath"
                            v-model="job.apiPath"
                            maxLength="400"
                            trim/>
              </BFormGroup>
              <BFormGroup
                size="sm" class="mb-1"
                label="인증 경로"
                label-for="authPath"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="authPath"
                            v-model="job.authPath"
                            maxLength="400"
                            trim/>
              </BFormGroup>
              <!-- port# -->
              <BFormGroup
                size="sm"  class="mb-1"
                label="서버 포트 번호"
                label-for="portNo"
                label-cols-md="3"
                valid-feedback="포트번호 형식입니다."
                invalid-feedback="포트번호 1000 ~ 65535 사이 값"
                :state="job.port > 999 && job.port < 65536"
              >
                <BFormInput size="sm"
                            id="portNo"
                            name="port"
                            type="number"
                            :state="job.port > 999 && job.port < 65536"
                            v-model="job.port"
                />
              </BFormGroup>
              <!-- dbName -->
              <BFormGroup
                size="sm" class="mb-1"
                label="사이트 코드"
                label-for="siteCode"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="siteCode"
                            v-model="job.siteCode"
                            maxLength="40"
                            trim/>
              </BFormGroup>
              <!-- account -->
              <BFormGroup
                size="sm" class="mb-1"
                label="서버 접속 계정"
                label-for="account"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="account"
                            autocomplete="nope"
                            v-model="job.account"
                            maxLength="40"
                            trim/>
              </BFormGroup>
              <!-- password -->
              <BFormGroup
                size="sm" class="mb-1"
                label="접속 비밀번호"
                label-for="jobPasswd"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="jobPasswd"
                            type="password"
                            autocomplete="nope"
                            v-model="job.password"
                            maxLength="40"
                            trim/>
              </BFormGroup>
              <!-- auth-token -->
              <BFormGroup
                v-if="false"
                size="sm" class="mb-1"
                label="접속 토큰정보"
                label-for="authToken"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="authToken" type="password"
                            v-model="job.authToken"
                            maxLength="40"
                            trim/>
              </BFormGroup>
              <!-- auth-token -->
              <BFormGroup
                size="sm" class="mb-1"
                label="비고"
                label-for="remark"
                label-cols-md="3"
              >
                <BFormInput size="sm" id="remark"
                            v-model="job.remark"
                            maxLength="40"
                            trim/>
              </BFormGroup>
            </div>





            <div class="row justify-content-between p-3">
              <BButtonGroup>
                <BButton type="submit" variant="primary">
                  <BIconSaveFill/> 설정 저장
                </BButton>
                <BButton type="button" variant="info" class="ml-3" @click="showJobLogModal(job.jobId)">
                  <BIconCardList/> 로그 보기
                </BButton>
              </BButtonGroup>

              <BButton variant="danger" @click="deleteRecord">
                <BIconTrashFill/>
                삭 제
              </BButton>
            </div>
          </BCard>
        </BForm>
      </CCardBody>
    </CCard>


    <BModal id="job-log"
            ref="jobLog"
            size="xl"
            header-bg-variant="dark"
            header-text-variant="light"
            body-bg-variant="secondary"
            body-text-variant="dark"
            footer-bg-variant="dark"
            footer-text-variant="dark"
            scrollable>


      <template #modal-title>
        <div class="c-dark-theme">
        <BInputGroup>
          <template #append>
            <strong class="mr-3">
              배치작업 로그조회
            </strong>

            <BFormSelect :options="jobOpts"
                         size="sm"
                         style="background-color:#2f303a"
                         @input="getJobLog(jobId)"
                         v-model="jobId"/>

            <BFormInput size="sm" class="float-right" type="date" v-model="fromDts"></BFormInput>
            <BFormInput size="sm" class="float-right"  type="date" v-model="toDts"></BFormInput>
            <BButton size="sm" variant="info" @click="getJobLog(jobId)"> <BIconSearch/> 검 색</BButton>

          </template>



        </BInputGroup>
        </div>
      </template>

      <BTable small sticky-header="700px" selectable hover responsive bordered dark
              class="text-nowrap small"
              thead-class="text-center small"
              ref="jobLogTable"
              id="jobLogTable"
              select-mode="single"
              style="min-height:700px"
              selectedVariant="success"
              :per-page="logPerPage"
              :current-page="logCurrPage"
              :items="jobLogs"
              :fields="logFields"
              >


        <template #cell(log)="{value}">
          <BBadge :variant="value.code===200||value.code===0? 'success':'danger'">
            {{ value.code===200 || value.code===0 ?'성공':'실패'}}
          </BBadge>
          <BBadge variant="secondary">{{value.code}}</BBadge>
          {{ value.message}}
        </template>


      </BTable>

      <BRow>
        <BCol>
          <BPagination
            size="sm"
            v-model="logCurrPage"
            :total-rows="jobLogRows"
            :per-page="logPerPage"
            aria-controls="jobLogTable"
          ></BPagination>
        </BCol>
      </BRow>

    </BModal>


  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
} from '@/common/utils';
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import moment from "moment/moment";

const _config_job = {
  jobId      : '0000',
  name       : '',
  description: '작업 설명',
  enabled    : false,
  scheduled  : true,
  runSchedule : '',
  eventName  : '',
  dbType     : '5',
  serverIp   : '127.0.0.1',
  port       : 9999,
  baseUrl    : '',
  authPath   : '',
  apiPath    : '',
  siteCode   : '',
  account    : '',
  password   : '',
  authToken  : '',
  remark     : '',
  status     : '10',
  runHistory : [],
};


//----------------------------------------------------------------------------------------------------
export default {
  name: "BatchService",
  components: {
    VueCronEditorBuefy
  },
  computed: {
    jobLogRows(){
      return this.jobLogs.length;
    }
  },

  data () {
    return {

      job : null,
      jobRows: [],
      jobFields: [
        { key: 'jobId'       , label: '작업코드' },
        { key: 'name'        , label: '작업명' },
        { key: 'description' , label: '작업상세' },
        { key: 'status'      , label: '상태' },
        { key: 'enabled'     , label: '사용여부', formatter: (v)=>{ return v? '사용':'중지'} },
        { key: 'runSchedule' , label: '작업일정' },
        // { key: 'runOnEvent'  , label: '이벤트작업' },
        // { key: 'eventName'   , label: '이벤트명' },
        // { key: 'dbType'      , label: '' },
        // { key: 'serverIp'    , label: '서버IP' },
        // { key: 'port'        , label: '' },
        // { key: 'apiUrl'      , label: '' },
        // { key: 'dbName'      , label: '' },
        // { key: 'account'     , label: '' },
        // { key: 'password'    , label: '' },
        // { key: 'authToken'   , label: '' },

        { key: 'remark'      , label: '비고' },
      ],
      expression: '*/1 * * * *',
      locale : {
        ko: {
          every: "Every",
          mminutes: "분 간격",
          hoursOnMinute: "시/분",
          daysAt: "일/시:분",
          at: "시:분",
          onThe: "일자",
          dayOfEvery: "월",
          monthsAt: "시:분",
          everyDay: "Every",
          mon: "월",
          tue: "화",
          wed: "수",
          thu: "목",
          fri: "금",
          sat: "토",
          sun: "일",
          hasToBeBetween: "Has to be between",
          and: "and",
          minutes: "분간격",
          hourly: "시간간격",
          daily: "일간",
          weekly: "주간",
          monthly: "월간",
          advanced: "커스텀설정",
          cronExpression: "크론 표현식:"
        }
      },

      isApplyJob: false,
      // packetTypeMap : this.$store.state.codeMaps['PACKET_TYPE'],
      // packetTypeOpts : this.$store.state.codeOpts['PACKET_TYPE'],
      // encTypeMap: this.$store.state.codeMaps['ENC_TYPE'],
      // encTypeOpts: this.$store.state.codeOpts['ENC_TYPE'],
      dbTypeOpts: this.$store.state.codeOpts['DB_TYPE'],

      jobOpts: [],
      jobLogVisible: false,
      jobId: '',
      jobLogs: [],
      fromDts: moment().add(-1, 'months').format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),

      logFields: [
        { key: 'jobId'       , label: '작업코드' },
        { key: 'name'        , label: '작업명' },
        { key: 'createdAt'   , sortable: true, label: '로그일시', formatter: v=>{return (v? moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
        { key: 'log'        , label: '로그' },
      ],

      logPerPage: 20,
      logCurrPage: 1,
      progCount: 0,
      totalCount: 0,


    }

  },
  async created(){
    try{
      console.log("--- Batch Services mounted---------------------");
      await this.getJob();
    }catch(err){
      console.error(err);
    }
  },


  mounted() {
    console.log("--- Batch Services mounted---------------------");
    // below is not work!
  },

  methods: {
    statusName(sts){
      switch(sts){
        case '00': return '등록';
        case '10': return '초기화';
        case '20': return '작업대기';
        case '30': return '실행중';
        case '99': return '에러';
        default: return sts;
      }
    },

    statusVariant(sts){
      switch(sts){
        case '00': return 'dark';
        case '10': return 'success';
        case '20': return 'info';
        case '30': return 'warning';
        case '99': return 'danger';
        default: return sts;
      }
    },

    async getJob(){
      console.log('getJob called.....');
      try{
        const r = await apiCall('get', `/api/job`);
        if(r.code===200){
          this.jobOpts = [];
          this.jobRows = r.result;
          this.jobRows.map(e=>{ this.jobOpts.push({text: `[${e.jobId}] ${e.name}: ${e.description}`, value: e.jobId}) });
        }else{
          await this.alertDanger(r.message, r.code);
        }

        await this.toastResult(r, '조회', false);

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },


    async addJob(){
      this.job = cloneVar(_config_job);
      this.job.jobId = ('0000'+(this.jobRows.length+1)).slice(-4);
      this.job.name = this.job.jobId + '-job-name'
      this.job.description = '작업 상세 내용'

      const r = await apiCall('post', `/api/job`, this.job);
      if(r.code===200){
        this.job = r.result;
        await this.getJob();
      }
      await this.toastResult(r);
    },


    async jobSelected(item){
      console.log("------------ jobSelected ------------", item.length);
      if( item.length === 0 ) {
        this.job = null;
      }else{
        this.job = item.pop();
        // this.$root.$emit('bv::toggle::collapse', 'serviceCollapse');
      }
    },

    async applyJob(){
      try{
        this.isApplyJob = true;
        await this.toastInfo(`배치작업 정보를 시스템에 적용 중 입니다`);
        const r = await apiCall('get', `/api/job/apply`);
        await this.toastResult(r, `배치작업 변경 정보 시스템 적용`);
        if(r.code===200){
          await this.alertSuccess('배치작업 변경이 적용 되었습니다.');
          await this.getJob();
        }else{
          await this.alertDanger('적용 실패', r.code);
        }

      }catch(err){
        await this.alertError(err);
      }finally{
        this.isApplyJob = false;
      }
    },


    async jobSubmit(evt){
      evt.preventDefault();
      console.log( 'jobSubmit------------->', this.service );
      try{
        const r = await apiCall('put', `/api/job/${this.job._id}`, this.job );
        console.log('jobSubmit------------->' , r);
        await this.toastResult(r);
      }catch(err){
        console.log(err);
      }
    },


    async deleteRecord(){
      // let r = null, msg = null, notice = null;
      try{

        const obj = this.job;

        const confirmMsg = `${obj.name} 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        const r = await apiCall('DEL', `/api/job/${obj._id}`);
        // console.log( r );
        if( r.code===200 ){
          await this.getJob();
        }else{
          await this.toastInfo('삭제 에러: '+r.message, 'Error', 'danger' );
        }
      }catch(err){
        await this.alertError(err);
      }
    },


    showJobLogModal(jobId){
      this.$refs['jobLog'].show();
      this.getJobLog(jobId);
    },

    async getJobLog(jobId=''){
      try{
        this.jobLogs = [];
        this.jobId = jobId;
        const r = await apiCall('get', `/api/job/log/${jobId}?fromDts=${this.fromDts}&toDts=${this.toDts}`);
        if(r.code===200){
          this.jobLogs = r.result;
        }else{
          await this.alertDanger(r.message, r.code);
        }

        await this.toastResult(r, '조회', false);

      }catch(err){
        await this.alertDanger(err.message);
        console.error( err );
      }
    }




  }
}
</script>
