<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 배치작업 설정 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">
      <div slot="header">
         <CIcon name="cilApplications" class="mr-2"/> <strong> 시스템 서비스</strong>
        <div class="card-header-actions">
          <small class="text-muted">시스템 서비스 정의, 작업 설정, 데이터백업 </small>
        </div>
      </div>

      <BTabs>
        <BTab title="서비스 및 작업설정">

          <BRow class="mt-1">
            <BCol xl="6">
              <SystemService/>
            </BCol>

            <BCol xl="6">
              <BatchService/>
            </BCol>
          </BRow>

        </BTab>

        <BTab title="시스템 백업관리">
          <SystemManage/>
        </BTab>
      </BTabs>


    </BCard>


  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';

import SystemManage from "@/views/components/SystemManage.vue";
import SystemService from "@/views/components/services/SystemService.vue";
import BatchService from "@/views/components/services/BatchService.vue";



// import qs from 'querystring';
// import moment from "moment";


//----------------------------------------------------------------------------------------------------
export default {
  name: "Services",
  components: {
    BatchService,
    SystemService,
    SystemManage,
  },
  computed: {},
  data () {
    return {
      progCount: 0,
      totalCount: 0,
      paramMap: {},
      numOfRecord: 10,


    }

  },
  async created(){
    try{
      console.log("--- Services mounted---------------------");
    }catch(err){
      console.log(err);
    }
  },


  mounted() {
    console.log("--- Services mounted---------------------");
    // below is not work!
  },

  methods: {

  }
}
</script>
